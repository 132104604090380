import React, { Component } from 'react';

class GuestBook extends Component {

  constructor() {
    super();
    this.state = {
      name: "",
      message: ""
    };
  }

  handleNameChange = evt => {
    this.setState({ name: evt.target.value });
  };
  handleMessageChange = evt => {
    this.setState({ message: evt.target.value });
  };

  canBeSubmitted() {
    const { name, message } = this.state;
    return name.length > 0 && message.length > 0;
  }
  
  render() {

    const isEnabled = this.canBeSubmitted();

    return (

      <div className="guest-book">
          <form name="guest-book" method="post" action="/sent/" data-netlify="true" netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="guest-book" />
            <input type="text" className="form-control" placeholder="Full Name" id="name" name="name" value={this.state.name} onChange={this.handleNameChange} />
            <textarea type="text" className="form-control" placeholder="Your Message (or secrets)" id="message" name="message" value={this.state.message} onChange={this.handleMessageChange} />
            <div className="link-box text-center">
              <button disabled={!isEnabled} type="submit" className="btn green">
                <span className="shadow"></span>
                <span className="button">Send</span>
              </button>
            </div>
          </form>
      </div>
    );
  }
}

export default GuestBook

