import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// Import Images
import JBSLogo from '../images/jbs-logo.svg'
import BGStar from '../images/pink-background-star.svg'
import StarButton from '../images/star-btn.svg'
import JackieEd from '../images/jackie-and-ed.svg'

// Import Illustrations
import Sweatpants from '../images/illustrations/sweatpants.svg'
import Diper from '../images/illustrations/diper.svg'
import Rattle from '../images/illustrations/rattle.svg'
import TeddyBear from '../images/illustrations/teddy-bear.svg'
import Bottle from '../images/illustrations/bottle.svg'
import Dummy from '../images/illustrations/dummy.svg'

// Import Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import GuestBook from "../components/guestbook"

const IndexPage = () => (
  <Layout>
    <Seo title="Jackie's Baby Shower - Spring Thring" />
    <Helmet>
      <meta name="title" content="Jackie's Baby Shower - Spring Thring" />
      <meta name="description" content="Celebrate the upcoming Spring Thring! Tune in Saturday March 12, 2022 at 5:00 pm EST." />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://springthring.com" />
      <meta property="og:title" content="Jackie's Baby Shower - Spring Thring" />
      <meta property="og:description" content="Celebrate the upcoming Spring Thring! Tune in Saturday March 12, 2022 at 5:00 pm EST." />
      <meta property="og:image" content="https://springthring.com/JBS-Preview.jpg" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://springthring.com" />
      <meta property="twitter:title" content="Jackie's Baby Shower - Spring Thring" />
      <meta property="twitter:description" content="Celebrate the upcoming Spring Thring! Tune in Saturday March 12, 2022 at 5:00 pm EST." />
      <meta property="twitter:image" content="https://springthring.com/JBS-Preview.jpg" />
    </Helmet>
    <div className="section">

      <Link to="/" className="logo">
        <img src={JBSLogo} alt="Jackie's Baby Shower" />
      </Link>

      <div className="head-btn">
        <a href="https://www.babylist.com/baby-jackie-thring" target="_blank" className="btn red">
          <span className="shadow"></span>
          <span className="button">Registry</span>
        </a>
      </div>

      <div className="section no-bottom">
        <div className="container text-center">
          <div className="box full-height background-peach box-radius">

            <span className="shape hero-burst">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Burst" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <path style={{fill:'#FFF4F2'}} id="Burst-2" data-name="Burst" d="M16.193,3.661c-.044,3.531-.035,3.557,3.51,2.271A2.491,2.491,0,0,1,23.1,7c1,1.613-.31,2.2-1.222,2.98-.187.159-.436.243-.633.394-3.407,2.614-3.437,2.636-1.028,6.278.848,1.282,1.466,2.369.012,3.679-1.369,1.232-2.27.527-3.437-.349-3.276-2.459-2.757-2.683-4.562,1.306-.409.9-.721,1.852-1.1,2.772-.505,1.228-1.517,1.124-2.52.878a1.615,1.615,0,0,1-1.294-2.06c.259-1.629.456-3.27.764-4.89.237-1.244.2-1.951-1.426-1.518A41.775,41.775,0,0,1,2.5,17.1C.944,17.35.452,16.251.1,15.129-.335,13.77.773,13.5,1.7,13.1c3.827-1.633,3.8-1.626,1.473-5.217C2.457,6.788,1.077,5.8,2.462,4.2c1.594-1.847,2.348-1.87,4.684.144C9.615,6.472,9.584,6.459,10.9,3.4c.476-1.1.471-2.543,1.811-3.122,2.124-.918,3.517.45,3.48,3.379" transform="translate(44 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-heart">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Heart" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                  <path style={{fill:'#FFF4F2'}} id="Heart-2" data-name="Heart" d="M.032,7.774A7.5,7.5,0,0,1,.82,3.641C1.7,1.932,2.753.307,4.928.056A5.477,5.477,0,0,1,10.652,2.7c.7,1.086.836,2.861,2.073,3.185,1.265.331,1.874-1.4,2.893-2.074,2.3-1.521,5.755-1.692,7.516-.32,1.971,1.535,2.391,3.492,1.182,6.782-2.54,6.912-7.987,10.547-14.693,12.551-1.73.517-3.231-.672-4.346-1.924A21.565,21.565,0,0,1,.032,7.774" transform="translate(44 1615)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-heart-two">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Heart" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                  <path style={{fill:'#FFF4F2'}} id="Heart-2" data-name="Heart" d="M.032,7.774A7.5,7.5,0,0,1,.82,3.641C1.7,1.932,2.753.307,4.928.056A5.477,5.477,0,0,1,10.652,2.7c.7,1.086.836,2.861,2.073,3.185,1.265.331,1.874-1.4,2.893-2.074,2.3-1.521,5.755-1.692,7.516-.32,1.971,1.535,2.391,3.492,1.182,6.782-2.54,6.912-7.987,10.547-14.693,12.551-1.73.517-3.231-.672-4.346-1.924A21.565,21.565,0,0,1,.032,7.774" transform="translate(44 1615)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-burst-two">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Burst" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <path style={{fill:'#FFF4F2'}} id="Burst-2" data-name="Burst" d="M16.193,3.661c-.044,3.531-.035,3.557,3.51,2.271A2.491,2.491,0,0,1,23.1,7c1,1.613-.31,2.2-1.222,2.98-.187.159-.436.243-.633.394-3.407,2.614-3.437,2.636-1.028,6.278.848,1.282,1.466,2.369.012,3.679-1.369,1.232-2.27.527-3.437-.349-3.276-2.459-2.757-2.683-4.562,1.306-.409.9-.721,1.852-1.1,2.772-.505,1.228-1.517,1.124-2.52.878a1.615,1.615,0,0,1-1.294-2.06c.259-1.629.456-3.27.764-4.89.237-1.244.2-1.951-1.426-1.518A41.775,41.775,0,0,1,2.5,17.1C.944,17.35.452,16.251.1,15.129-.335,13.77.773,13.5,1.7,13.1c3.827-1.633,3.8-1.626,1.473-5.217C2.457,6.788,1.077,5.8,2.462,4.2c1.594-1.847,2.348-1.87,4.684.144C9.615,6.472,9.584,6.459,10.9,3.4c.476-1.1.471-2.543,1.811-3.122,2.124-.918,3.517.45,3.48,3.379" transform="translate(44 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-burst-three">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Burst" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <path style={{fill:'#FFF4F2'}} id="Burst-2" data-name="Burst" d="M16.193,3.661c-.044,3.531-.035,3.557,3.51,2.271A2.491,2.491,0,0,1,23.1,7c1,1.613-.31,2.2-1.222,2.98-.187.159-.436.243-.633.394-3.407,2.614-3.437,2.636-1.028,6.278.848,1.282,1.466,2.369.012,3.679-1.369,1.232-2.27.527-3.437-.349-3.276-2.459-2.757-2.683-4.562,1.306-.409.9-.721,1.852-1.1,2.772-.505,1.228-1.517,1.124-2.52.878a1.615,1.615,0,0,1-1.294-2.06c.259-1.629.456-3.27.764-4.89.237-1.244.2-1.951-1.426-1.518A41.775,41.775,0,0,1,2.5,17.1C.944,17.35.452,16.251.1,15.129-.335,13.77.773,13.5,1.7,13.1c3.827-1.633,3.8-1.626,1.473-5.217C2.457,6.788,1.077,5.8,2.462,4.2c1.594-1.847,2.348-1.87,4.684.144C9.615,6.472,9.584,6.459,10.9,3.4c.476-1.1.471-2.543,1.811-3.122,2.124-.918,3.517.45,3.48,3.379" transform="translate(44 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-burst-four">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Burst" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <path style={{fill:'#FFF4F2'}} id="Burst-2" data-name="Burst" d="M16.193,3.661c-.044,3.531-.035,3.557,3.51,2.271A2.491,2.491,0,0,1,23.1,7c1,1.613-.31,2.2-1.222,2.98-.187.159-.436.243-.633.394-3.407,2.614-3.437,2.636-1.028,6.278.848,1.282,1.466,2.369.012,3.679-1.369,1.232-2.27.527-3.437-.349-3.276-2.459-2.757-2.683-4.562,1.306-.409.9-.721,1.852-1.1,2.772-.505,1.228-1.517,1.124-2.52.878a1.615,1.615,0,0,1-1.294-2.06c.259-1.629.456-3.27.764-4.89.237-1.244.2-1.951-1.426-1.518A41.775,41.775,0,0,1,2.5,17.1C.944,17.35.452,16.251.1,15.129-.335,13.77.773,13.5,1.7,13.1c3.827-1.633,3.8-1.626,1.473-5.217C2.457,6.788,1.077,5.8,2.462,4.2c1.594-1.847,2.348-1.87,4.684.144C9.615,6.472,9.584,6.459,10.9,3.4c.476-1.1.471-2.543,1.811-3.122,2.124-.918,3.517.45,3.48,3.379" transform="translate(44 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-circle">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Circle" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <circle style={{fill:'#FFF4F2'}} id="Circle-2" data-name="Circle" cx="8.5" cy="8.5" r="8.5" transform="translate(48 1618)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-circle-two">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Circle" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <circle style={{fill:'#FFF4F2'}} id="Circle-2" data-name="Circle" cx="8.5" cy="8.5" r="8.5" transform="translate(48 1618)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-circle-three">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Circle" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <circle style={{fill:'#FFF4F2'}} id="Circle-2" data-name="Circle" cx="8.5" cy="8.5" r="8.5" transform="translate(48 1618)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-circle-three">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Circle" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <circle style={{fill:'#FFF4F2'}} id="Circle-2" data-name="Circle" cx="8.5" cy="8.5" r="8.5" transform="translate(48 1618)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-circle-four">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Circle" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                  <circle style={{fill:'#FFF4F2'}} id="Circle-2" data-name="Circle" cx="8.5" cy="8.5" r="8.5" transform="translate(48 1618)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-star">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Star" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                  <path style={{fill:'#FFF4F2'}} id="Star-2" data-name="Star" d="M9.189.006c1.042-.079,1.472.65,1.8,1.436C11.74,3.282,12.537,5.111,13.152,7a2.468,2.468,0,0,0,2.393,2.047,28.167,28.167,0,0,1,4.6.961c1.728.449,1.927,1.3.642,2.605a9.952,9.952,0,0,1-3.562,2.228,4.443,4.443,0,0,0-2.97,4.433,16.727,16.727,0,0,1-1.2,5.311c-.74,2.006-1.637,2.1-2.876.3a22.928,22.928,0,0,1-2.535-5.927c-.431-1.3-.943-2.252-2.553-2.137a9.029,9.029,0,0,1-1.935-.387c-1.289-.21-3.028-.107-3.158-1.754-.115-1.46,1.427-2.224,2.662-2.615C5.921,11.029,6.828,8.7,6.89,5.58a21.759,21.759,0,0,1,.935-4.348A1.412,1.412,0,0,1,9.189.006" transform="translate(45 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-star-two">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Star" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                  <path style={{fill:'#FFF4F2'}} id="Star-2" data-name="Star" d="M9.189.006c1.042-.079,1.472.65,1.8,1.436C11.74,3.282,12.537,5.111,13.152,7a2.468,2.468,0,0,0,2.393,2.047,28.167,28.167,0,0,1,4.6.961c1.728.449,1.927,1.3.642,2.605a9.952,9.952,0,0,1-3.562,2.228,4.443,4.443,0,0,0-2.97,4.433,16.727,16.727,0,0,1-1.2,5.311c-.74,2.006-1.637,2.1-2.876.3a22.928,22.928,0,0,1-2.535-5.927c-.431-1.3-.943-2.252-2.553-2.137a9.029,9.029,0,0,1-1.935-.387c-1.289-.21-3.028-.107-3.158-1.754-.115-1.46,1.427-2.224,2.662-2.615C5.921,11.029,6.828,8.7,6.89,5.58a21.759,21.759,0,0,1,.935-4.348A1.412,1.412,0,0,1,9.189.006" transform="translate(45 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-star-three">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Star" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                  <path style={{fill:'#FFF4F2'}} id="Star-2" data-name="Star" d="M9.189.006c1.042-.079,1.472.65,1.8,1.436C11.74,3.282,12.537,5.111,13.152,7a2.468,2.468,0,0,0,2.393,2.047,28.167,28.167,0,0,1,4.6.961c1.728.449,1.927,1.3.642,2.605a9.952,9.952,0,0,1-3.562,2.228,4.443,4.443,0,0,0-2.97,4.433,16.727,16.727,0,0,1-1.2,5.311c-.74,2.006-1.637,2.1-2.876.3a22.928,22.928,0,0,1-2.535-5.927c-.431-1.3-.943-2.252-2.553-2.137a9.029,9.029,0,0,1-1.935-.387c-1.289-.21-3.028-.107-3.158-1.754-.115-1.46,1.427-2.224,2.662-2.615C5.921,11.029,6.828,8.7,6.89,5.58a21.759,21.759,0,0,1,.935-4.348A1.412,1.412,0,0,1,9.189.006" transform="translate(45 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-star-four">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Star" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                  <path style={{fill:'#FFF4F2'}} id="Star-2" data-name="Star" d="M9.189.006c1.042-.079,1.472.65,1.8,1.436C11.74,3.282,12.537,5.111,13.152,7a2.468,2.468,0,0,0,2.393,2.047,28.167,28.167,0,0,1,4.6.961c1.728.449,1.927,1.3.642,2.605a9.952,9.952,0,0,1-3.562,2.228,4.443,4.443,0,0,0-2.97,4.433,16.727,16.727,0,0,1-1.2,5.311c-.74,2.006-1.637,2.1-2.876.3a22.928,22.928,0,0,1-2.535-5.927c-.431-1.3-.943-2.252-2.553-2.137a9.029,9.029,0,0,1-1.935-.387c-1.289-.21-3.028-.107-3.158-1.754-.115-1.46,1.427-2.224,2.662-2.615C5.921,11.029,6.828,8.7,6.89,5.58a21.759,21.759,0,0,1,.935-4.348A1.412,1.412,0,0,1,9.189.006" transform="translate(45 1613)"/>
                </g>
              </svg>
            </span>
            <span className="shape hero-star-five">
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                <g id="Star" transform="translate(-41 -1611)">
                  <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                  <path style={{fill:'#FFF4F2'}} id="Star-2" data-name="Star" d="M9.189.006c1.042-.079,1.472.65,1.8,1.436C11.74,3.282,12.537,5.111,13.152,7a2.468,2.468,0,0,0,2.393,2.047,28.167,28.167,0,0,1,4.6.961c1.728.449,1.927,1.3.642,2.605a9.952,9.952,0,0,1-3.562,2.228,4.443,4.443,0,0,0-2.97,4.433,16.727,16.727,0,0,1-1.2,5.311c-.74,2.006-1.637,2.1-2.876.3a22.928,22.928,0,0,1-2.535-5.927c-.431-1.3-.943-2.252-2.553-2.137a9.029,9.029,0,0,1-1.935-.387c-1.289-.21-3.028-.107-3.158-1.754-.115-1.46,1.427-2.224,2.662-2.615C5.921,11.029,6.828,8.7,6.89,5.58a21.759,21.759,0,0,1,.935-4.348A1.412,1.412,0,0,1,9.189.006" transform="translate(45 1613)"/>
                </g>
              </svg>
            </span>
            
            <span className="ill diper">
              <img src={Diper} alt="Blue Diper" />
            </span>
            <span className="ill rattle">
              <img src={Rattle} alt="Baby Rattle" />
            </span>
            <span className="ill teddy-bear">
              <img src={TeddyBear} alt="Pink and Green Teddy Bear" />
            </span>
            <span className="ill bottle">
              <img src={Bottle} alt="Baby Bottle" />
            </span>
            <span className="ill dummy">
              <img src={Dummy} alt="Baby Dummy" />
            </span>

            <div className="bgstar">
              <img src={BGStar} alt="Background Star" />
            </div>
            <div className="content-wrap-middle">
              <div className="content">
                <span className="text-block text-blue all-caps">Celebrate The Upcoming</span>
                <h1 className="all-caps text-pink text-shadow no-top">Spring Thring</h1>
                <p className="text-black no-top-bottom">Tune in Saturday March 12, 2022<br/>Starts 5:00 pm EST</p>
                <div className="link-box no-bottom">
                  <a href="https://us04web.zoom.us/j/74075286504?pwd=NR64V06NhHXmsqx-6c-fgrr9JCKDeA.1" target="_blank" className="btn pink">
                    <span className="shadow"></span>
                    <span className="button">Join Virtual Shower</span>
                  </a>
                  <span className="helper-text">
                    <span className="arrow">
                      <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 40.705 37.453">
                        <defs>
                          <clipPath id="clip-path">
                            <rect style={{fill:'#ff87a2'}} id="Rectangle_456" data-name="Rectangle 456" width="37.453" height="40.705" />
                          </clipPath>
                        </defs>
                        <g id="Group_16" data-name="Group 16" transform="translate(40.705 0) rotate(90)">
                          <g id="Group_15" data-name="Group 15" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path style={{fill:'#ff87a2'}} id="Path_40" data-name="Path 40" d="M10.849,40.705l2.124-2.218L5.948,31.471c1.05,0,1.908,0,2.767,0a27.565,27.565,0,0,0,9.967-1.6c10.083-3.85,16.24-11.06,18.334-21.68A39.393,39.393,0,0,0,37.424,0H34.25c0,1.248.006,2.458,0,3.669a23.444,23.444,0,0,1-2.716,11.093A24.186,24.186,0,0,1,12.8,28c-2.2.317-4.455.275-6.684.4l-.039-.185,7.029-6.939-2.276-2.241L0,29.883,10.849,40.705" transform="translate(0)"/>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <p className="tiny font-bold text-pink no-top-bottom">
                      Click here on<br/>
                      March 12th
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section no-bottom">
        <div className="container">
          <div className="row clearfix">
            <div className="column break-full-760">
              <div className="padding">
                <div className="box shorter background-pink box-radius overlay-hidden">
                  <span className="ill sweatpants">
                    <img src={Sweatpants} alt="Green and Cream Sweatpants" />
                  </span>
                  <div className="box-padding" style={{zIndex:2}}>
                    <p className="lead text-white no-top-bottom">
                      <span className="block text-dark-pink">It’s a Virtual Baby Shower!</span>
                      While we wish we could celebrate in person, at least now you can keep your sweatpants on.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column break-full-760">
              <div className="padding">
                <div className="box shorter background-light-blue box-radius text-center">
                  <div className="content-wrap-middle">
                    <div className="content">
                      <div className="box-padding no-top-bottom">
                        <h3 className="all-caps text-white no-top">Registry</h3>
                        <p className="text-blue">If you were curious, Jackie &amp; Ed have created a registry at <a href="https://www.babylist.com/baby-jackie-thring" target="_blank" className="text-link blue">babylist.com</a>. Below is the link if you’re interested.</p>
                        <div className="link-box no-bottom">
                          <a href="https://www.babylist.com/baby-jackie-thring" target="_blank" className="btn blue">
                            <span className="shadow"></span>
                            <span className="button">Go To Registry</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section no-top-bottom">
        <div className="container">
          <div className="row clearfix">
            <div className="column shorter break-full-760">
                <div className="box background-white">
                  <a href="https://calendar.google.com/event?action=TEMPLATE&amp;tmeid=M2FwbXBwZWpjZjMybm12MjFoZTAydmpxZDIgdmc5NTBzMjN2YXQ3bTVlbWF0aDBvdWdjcW9AZw&amp;tmsrc=vg950s23vat7m5emath0ougcqo%40group.calendar.google.com" target="_blank" className="star-btn text-center">
                    <img src={StarButton} alt="Set Reminder Button" />
                    <p className="text-white font-bold no-top-bottom">Click here<br/>to set a Google<br/>reminder</p>                  
                  </a>
                  <span className="shape reminder-heart">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                      <g id="Heart" transform="translate(-41 -1611)">
                        <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                        <path style={{fill:'#BBBDD9'}} id="Heart-2" data-name="Heart" d="M.032,7.774A7.5,7.5,0,0,1,.82,3.641C1.7,1.932,2.753.307,4.928.056A5.477,5.477,0,0,1,10.652,2.7c.7,1.086.836,2.861,2.073,3.185,1.265.331,1.874-1.4,2.893-2.074,2.3-1.521,5.755-1.692,7.516-.32,1.971,1.535,2.391,3.492,1.182,6.782-2.54,6.912-7.987,10.547-14.693,12.551-1.73.517-3.231-.672-4.346-1.924A21.565,21.565,0,0,1,.032,7.774" transform="translate(44 1615)"/>
                      </g>
                    </svg>
                  </span>
                  <span className="shape reminder-star">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                      <g id="Star" transform="translate(-41 -1611)">
                        <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                        <path style={{fill:'#BBBDD9'}} id="Star-2" data-name="Star" d="M9.189.006c1.042-.079,1.472.65,1.8,1.436C11.74,3.282,12.537,5.111,13.152,7a2.468,2.468,0,0,0,2.393,2.047,28.167,28.167,0,0,1,4.6.961c1.728.449,1.927,1.3.642,2.605a9.952,9.952,0,0,1-3.562,2.228,4.443,4.443,0,0,0-2.97,4.433,16.727,16.727,0,0,1-1.2,5.311c-.74,2.006-1.637,2.1-2.876.3a22.928,22.928,0,0,1-2.535-5.927c-.431-1.3-.943-2.252-2.553-2.137a9.029,9.029,0,0,1-1.935-.387c-1.289-.21-3.028-.107-3.158-1.754-.115-1.46,1.427-2.224,2.662-2.615C5.921,11.029,6.828,8.7,6.89,5.58a21.759,21.759,0,0,1,.935-4.348A1.412,1.412,0,0,1,9.189.006" transform="translate(45 1613)"/>
                      </g>
                    </svg>
                  </span>
                  <span className="shape reminder-star-two">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                      <g id="Star" transform="translate(-41 -1611)">
                        <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)"/>
                        <path style={{fill:'#BBBDD9'}} id="Star-2" data-name="Star" d="M9.189.006c1.042-.079,1.472.65,1.8,1.436C11.74,3.282,12.537,5.111,13.152,7a2.468,2.468,0,0,0,2.393,2.047,28.167,28.167,0,0,1,4.6.961c1.728.449,1.927,1.3.642,2.605a9.952,9.952,0,0,1-3.562,2.228,4.443,4.443,0,0,0-2.97,4.433,16.727,16.727,0,0,1-1.2,5.311c-.74,2.006-1.637,2.1-2.876.3a22.928,22.928,0,0,1-2.535-5.927c-.431-1.3-.943-2.252-2.553-2.137a9.029,9.029,0,0,1-1.935-.387c-1.289-.21-3.028-.107-3.158-1.754-.115-1.46,1.427-2.224,2.662-2.615C5.921,11.029,6.828,8.7,6.89,5.58a21.759,21.759,0,0,1,.935-4.348A1.412,1.412,0,0,1,9.189.006" transform="translate(45 1613)"/>
                      </g>
                    </svg>
                  </span>
                  <span className="shape reminder-burst">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 30 30">
                      <g id="Burst" transform="translate(-41 -1611)">
                        <rect style={{fill:'none'}} id="Background" width="30" height="30" transform="translate(41 1611)" />
                        <path style={{fill:'#BBBDD9'}} id="Burst-2" data-name="Burst" d="M16.193,3.661c-.044,3.531-.035,3.557,3.51,2.271A2.491,2.491,0,0,1,23.1,7c1,1.613-.31,2.2-1.222,2.98-.187.159-.436.243-.633.394-3.407,2.614-3.437,2.636-1.028,6.278.848,1.282,1.466,2.369.012,3.679-1.369,1.232-2.27.527-3.437-.349-3.276-2.459-2.757-2.683-4.562,1.306-.409.9-.721,1.852-1.1,2.772-.505,1.228-1.517,1.124-2.52.878a1.615,1.615,0,0,1-1.294-2.06c.259-1.629.456-3.27.764-4.89.237-1.244.2-1.951-1.426-1.518A41.775,41.775,0,0,1,2.5,17.1C.944,17.35.452,16.251.1,15.129-.335,13.77.773,13.5,1.7,13.1c3.827-1.633,3.8-1.626,1.473-5.217C2.457,6.788,1.077,5.8,2.462,4.2c1.594-1.847,2.348-1.87,4.684.144C9.615,6.472,9.584,6.459,10.9,3.4c.476-1.1.471-2.543,1.811-3.122,2.124-.918,3.517.45,3.48,3.379" transform="translate(44 1613)"/>
                      </g>
                    </svg>
                  </span>
                </div>
            </div>
            <div className="column longer break-full-760">
              <div className="padding">
                <div className="box taller background-cream box-radius text-center">
                  <div className="box-padding no-top-bottom">
                    <p className="small font-bold no-top">The Details:</p>
                    <h2 className="all-caps text-pink no-top-bottom">
                      Saturday, March 12<br/>
                      Starts 5:00 PM EST
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="box background-green box-radius">
            <div className="box-padding">
              <div className="row clearfix">
                <div className="column break-full-760">
                  <div className="padding bigger no-top-bottom">
                    <h3 className="all-caps text-green no-top">Guest Book</h3>
                    <p className="text-green">Send your good wishes, words of wisdom, or a special message for both Jackie and Ed during this exciting time. Your tricks of the trade will be very much appreciated (share your secrets).</p>
                  </div>
                </div>
                <div className="column break-full-760">
                  <div className="padding bigger no-top-bottom">
                    <GuestBook/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section footer text-center">
        <div className="container">
          <span className="jackie-ed no-top">
           <img src={JackieEd} alt="Jackie and Ed illustration" />
          </span>
          <p className="tiny no-top-bottom">
            <span className="text-grey">Custom Designed for Jackie.</span><br/>
            <span className="text-black">Made by <a href="http://www.mywebvite.com" target="_blank" className="text-link black">MyWebvite.</a></span>
          </p>
          <a href="http://www.mywebvite.com" target="_blank" className="mywebvite-icon">
            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 100 100">
              <path d="M83.72,0.14H16.5c-8.99,0-16.28,7.29-16.28,16.28v67.22c0,8.99,7.29,16.28,16.28,16.28h67.22
              c8.99,0,16.28-7.29,16.28-16.28V16.42C100,7.43,92.71,0.14,83.72,0.14z M33.47,11.28c3.04,0,5.5,2.46,5.5,5.5s-2.46,5.5-5.5,5.5
              s-5.5-2.46-5.5-5.5S30.43,11.28,33.47,11.28z M16.85,11.28c3.04,0,5.5,2.46,5.5,5.5s-2.46,5.5-5.5,5.5s-5.5-2.46-5.5-5.5
              S13.81,11.28,16.85,11.28z M55.41,64.39c-3,2.78-7.64,2.78-10.64,0L11.35,33.41l77.48,0L55.41,64.39z M88.83,17.49
              c0,2.64-2.14,4.79-4.79,4.79H54.9c-2.64,0-4.79-2.14-4.79-4.79v-1.43c0-2.64,2.14-4.79,4.79-4.79h29.14c2.64,0,4.79,2.14,4.79,4.79
              V17.49z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
